import React from 'react'
import './Footer.css'
import logo from '../../static/images/went-logo-132x132.png'

export default () => (
  <div>
    <footer className="footer">
      <div className="container taCenter">
        <img src={logo} width="30px" height="30px" alt="logo" /><br/>
        <span>
          © Copyright {new Date().getFullYear()} All rights reserved. {' '}
          <a href="https://wehrenterprises.org/">Wehr Enterprises</a>.
        </span>
      <div><a href="/privacypolicy/">Privacy Policy</a> | <a href="/termsofuse/">Terms of Use</a></div>
      </div>
    </footer>
  </div>
)
